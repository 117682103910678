import { CameraStream } from '@/util/CameraStream';

export default class CameraStreamWithCanvas2D extends CameraStream {
  canvas: HTMLCanvasElement | null = null;

  canvasContext: CanvasRenderingContext2D | null = null;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  tickFunc: (canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D) => void = () => { };

  ticked = false;

  resetCanvas(c: HTMLCanvasElement, cc: CanvasRenderingContext2D): void {
    this.canvas = c;
    this.canvasContext = cc;

    if (this.ticked) {
      return;
    }
    this.ticked = true;

    requestAnimationFrame(this.tick());
  }

  beforeDestroy(): void {
    super.beforeDestroy();
    this.canvas = null;
  }

  tick(): () => void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    return () => {
      if (self.canvas === null || self.canvasContext === null) {
        return;
      }

      self.tickFunc(self.canvas, self.canvasContext);

      requestAnimationFrame(self.tick());
    };
  }
}
