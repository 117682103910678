import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Top from '@/views/Top.vue';
import MediastreamVideo from '@/views/MediastreamVideo.vue';
import QuickRetrospective from '@/views/QuickRetrospective.vue';
import QuickRetrospectiveDefine from '@/components/QuickRetrospective/TabDefine.vue';
import QuickRetrospectiveCreate from '@/components/QuickRetrospective/TabCreate.vue';
import QuickRetrospectiveResult from '@/components/QuickRetrospective/TabResults.vue';
import CalculateRetryBackoff from '@/views/CalculateRetryBackoff.vue';
import BackoffJitter from '@/views/BackoffJitter.vue';
import CalculateSLO from '@/views/CalculateSLO.vue';
import RegexpTrie from '@/views/RegexpTrie.vue';
import Sanbai from '@/views/Sanbai.vue';
import Ontei from '@/views/Ontei.vue';
import ReturnCommission from '@/views/ReturnCommission.vue';
import CompoundInterest from '@/views/CompoundInterest.vue';
import PasswordSaver from '@/views/PasswordSaver.vue';
import JsonYaml from '@/views/JsonYaml.vue';
import PfcBalance from '@/views/PfcBalance.vue';
import DynamicTimeline from '@/views/DynamicTimeline.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'TOP',
    component: Top,
    meta: {
      title: '',
      // draft: true, // draft mode
    },
  },
  {
    path: '/mediastream_video_test',
    name: 'MediastreamVideo',
    component: MediastreamVideo,
    meta: {
      title: 'Mediastreamを使ったカメラデバイスの表示テスト',
    },
  },
  {
    path: '/quick_retrospective',
    name: 'QuickRetrospective',
    component: QuickRetrospective,
    meta: {
      title: 'クイック振り返り',
    },
    children: [
      { path: '', redirect: 'create' },
      {
        path: 'create',
        name: 'QuickRetrospectiveCreate',
        component: QuickRetrospectiveCreate,
        meta: {
          title: 'クイック振り返り - 記録',
        },
      },
      {
        path: 'define',
        name: 'QuickRetrospectiveDefine',
        component: QuickRetrospectiveDefine,
        meta: {
          title: 'クイック振り返り - 作成',
        },
      },
      {
        path: 'result',
        name: 'QuickRetrospectiveResult',
        component: QuickRetrospectiveResult,
        meta: {
          title: 'クイック振り返り - 結果確認',
        },
      },
    ],
  },
  {
    path: '/calculate_retry_backoff',
    name: 'CalculateRetryBackoff',
    component: CalculateRetryBackoff,
    meta: {
      title: 'Backoff計算機',
    },
  },
  {
    path: '/backoff_jitter',
    name: 'BackoffJitter',
    component: BackoffJitter,
    meta: {
      title: 'Backoff時のJitterの様子',
    },
  },
  {
    path: '/calculate_slo',
    name: 'CalculateSLO',
    component: CalculateSLO,
    meta: {
      title: 'SLO計算機',
    },
  },
  {
    path: '/regexp_trie',
    name: 'RegexpTrie',
    component: RegexpTrie,
    meta: {
      title: 'Trie木で正規表現を作る',
    },
  },
  {
    path: '/sanbai',
    name: 'Sanbai',
    component: Sanbai,
    meta: {
      title: '三倍濃縮をいい感じにする',
    },
  },
  {
    path: '/ontei',
    name: 'Ontei',
    component: Ontei,
    meta: {
      title: '音程を見る（失敗）',
    },
  },
  {
    path: '/return_commission',
    name: 'ReturnCommission',
    component: ReturnCommission,
    meta: {
      title: '返済手数料計算',
      draft: true,
    },
  },
  {
    path: '/compound_interest',
    name: 'CompoundInterest',
    component: CompoundInterest,
    meta: {
      title: '複利計算',
    },
  },
  {
    path: '/pass_saver',
    name: 'PasswordSaver',
    meta: {
      title: 'パスワードセーバー',
    },
    redirect: '/pass_saver/dummy/',
    component: PasswordSaver,
    children: [
      {
        path: '*/success',
        name: 'PasswordSaverSuccess',
        redirect: () => {
          // eslint-disable-next-line no-return-assign
          Vue.nextTick(() => window.location.href = '/pass_saver/dummy/');
          return '';
        },
        meta: {
          title: 'パスワードセーバー',
        },
      },
      {
        path: '*',
        name: 'PasswordSaverInput',
        component: PasswordSaver,
        meta: {
          title: 'パスワードセーバー',
        },
      },
    ],
  },
  {
    path: '/json2yaml',
    name: 'json2yaml/yaml2json',
    component: JsonYaml,
    meta: {
      title: 'JSON <-> YAML, json2yaml/yaml2json',
    },
  },
  {
    path: '/pfc_balance',
    name: 'PFC Balance',
    component: PfcBalance,
    meta: {
      title: 'PFCバランス',
    },
  },
  {
    path: '/dynamic_timeline',
    name: 'Dynamic Timeline',
    component: DynamicTimeline,
    meta: {
      title: 'Dynamic Timeline',
      draft: true,
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

const DEFAULT_TITLE = 'tools.gomiba.co';
router.afterEach((to) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    document.title = to.meta?.title !== ''
      ? `${to.meta?.title} | ${DEFAULT_TITLE}`
      : DEFAULT_TITLE;
  });
});

export default router;
