export const FACING_MODE_ENVIRONMENT = 'environment';
export const FACING_MODE_USER = 'user';

export class CameraStream {
  mode = FACING_MODE_ENVIRONMENT;

  currentDeviceId = 'default';

  stream: MediaStream | null = null;

  deviceList: {[label: string]: string} = {};

  deviceLabels: string[] = [];

  async refreshDeviceList(): Promise<void> {
    this.deviceList = {};
    this.deviceLabels = [];

    const stream = await navigator.mediaDevices.getUserMedia({
      audio: false,
      video: true,
    });
    stream.getTracks().forEach((t) => t.stop());

    const devices = await navigator.mediaDevices.enumerateDevices();
    devices.forEach((device) => {
      if (device.kind !== 'videoinput') {
        return;
      }

      this.deviceList[device.label] = device.deviceId;
      this.deviceLabels.push(device.label);
    });
  }

  flipDevice(): void {
    this.mode = this.mode === FACING_MODE_USER ? FACING_MODE_ENVIRONMENT : FACING_MODE_USER;
    this.reopenMediaStream();
  }

  setDevice(label: string): void {
    this.currentDeviceId = this.deviceList[label];
    this.reopenMediaStream();
  }

  async reopenMediaStream(): Promise<void> {
    this.closeStreams();
    this.stream = await navigator.mediaDevices.getUserMedia({
      video: {
        facingMode: this.mode,
        deviceId: this.currentDeviceId,
      },
    });
  }

  closeStreams(): void {
    if (this.stream !== null) {
      this.stream.getTracks().forEach((track) => {
        track.stop();
      });
    }
    this.stream = null;
  }

  beforeDestroy(): void {
    this.closeStreams();
    this.deviceList = {};
    this.deviceLabels = [];
  }
}
