import Dexie from 'dexie';
import { Retrospective, RetrospectiveDefine } from './Interfaces';

const databaseName = 'QuickRetrospective';
const dbVersion = 1;

type DexieDatabase = {[P in keyof Dexie]: Dexie[P]};

interface Definitions {
  id?: string;
  definition: string;
}

interface Answers {
  id?: string;
  definitionId: string;
  answer: string;
}

interface DBInterface extends DexieDatabase {
  definitions: Dexie.Table<Definitions, number>;
  answers: Dexie.Table<Answers, number>;
}

export default class DB {
  db: DBInterface = new Dexie(databaseName) as DBInterface;

  open(): void {
    this.db.version(dbVersion).stores({
      definitions: 'id, definition',
      answers: 'id, definition_id, answer',
    });
  }

  close(): void {
    this.db.close();
  }

  async saveRetrospectiveDefine(retro: RetrospectiveDefine): Promise<void> {
    await this.db.definitions.add({
      id: retro.id,
      definition: JSON.stringify(retro),
    }).catch((err) => {
      console.log(err);
    });
  }

  async getRetrospectiveDefines(): Promise<RetrospectiveDefine[]> {
    return (await this.db.definitions.toArray()).map((d) => JSON.parse(d.definition));
  }

  async saveRetrospective(retro: Retrospective): Promise<void> {
    await this.db.answers.add({
      id: retro.id,
      definitionId: retro.definitionId,
      answer: JSON.stringify(retro),
    }).catch((err) => {
      console.log(err);
    });
  }

  static convertRetrospective(raw: Answers[]): Retrospective[] {
    return raw.map((d) => ({
      id: d.id,
      definitionId: d.definitionId,
      answers: JSON.parse(d.answer).answers,
    } as Retrospective));
  }

  async getRetrospective(): Promise<Retrospective[]> {
    return DB.convertRetrospective(await this.db.answers.toArray());
  }

  async getRetrospectiveByDefinitionId(id: string): Promise<Retrospective[]> {
    const raw = await this.db.answers.filter((answer) => answer.definitionId === id).toArray();
    return DB.convertRetrospective(raw);
  }
}
