export function RemoveSpaces(s: string): string {
  return s.replace(/\s/g, '');
}

export const validationRules = {
  required: (value: string): string|boolean => RemoveSpaces(value).length !== 0
    || '入力必須の項目です。',
};

export function truncateDecimal2(num: number): number {
  return Math.round((num + Number.EPSILON) * 100) / 100;
}

export interface TimeWindow {
  name: string;
  seconds: number;
}

export const SecondsMinute = 60;
export const SecondsHour = 60 * SecondsMinute;
export const SecondsDay = 24 * SecondsHour;
export const SecondsWeek = 7 * SecondsDay;

export const TimeWindowPerSecond: TimeWindow = {
  name: 'per second',
  seconds: 1,
};
export const TimeWindowPerMinute: TimeWindow = {
  name: 'per minute',
  seconds: SecondsMinute,
};
export const TimeWindowPerHour: TimeWindow = {
  name: 'per hour',
  seconds: SecondsHour,
};
export const TimeWindowPerDay: TimeWindow = {
  name: 'per day',
  seconds: SecondsDay,
};
export const TimeWindowPerWeek: TimeWindow = {
  name: 'per week (7days)',
  seconds: SecondsWeek,
};
export const TimeWindowPer2Week: TimeWindow = {
  name: 'per 2 weeks (14days)',
  seconds: 2 * SecondsWeek,
};
export const TimeWindowPerMonth: TimeWindow = {
  name: 'per month (30days)',
  seconds: SecondsDay * 30,
};
export const TimeWindowPerQuarter: TimeWindow = {
  name: 'per quarter (90days)',
  seconds: SecondsDay * 90,
};
export const TimeWindowPerYear: TimeWindow = {
  name: 'per year (365days)',
  seconds: SecondsDay * 365,
};

export function JustFitTimeFormat(seconds: number): string {
  if (seconds > SecondsDay) {
    return `${truncateDecimal2(seconds / SecondsDay)} days`;
  }

  if (seconds > SecondsHour) {
    return `${truncateDecimal2(seconds / SecondsHour)} hours`;
  }

  if (seconds > SecondsMinute) {
    return `${truncateDecimal2(seconds / SecondsMinute)} minutes`;
  }

  return `${truncateDecimal2(seconds)} seconds`;
}

export interface TreeItem {
  id: number | string;
  name: string;
  children: TreeItem[] | null;
}

export default {
  rules: validationRules,
};
